<template>
  <Breadcrumb v-bind="props">
    <slot></slot>
  </Breadcrumb>
</template>

<script>
import Breadcrumb from './Breadcrumb'

export default {
  name: 'BreadcrumbRouter',
  components: {
    Breadcrumb
  },
  props: {
    addClasses: [String, Array, Object],
    addLinkClasses: [String, Array, Object],
    addLastItemClasses: [String, Array, Object]
  },
  computed: {
    items () {
      const routes = this.$route.matched.filter(route => {
        return this.$t(route.name) || (route.meta && route.meta.label)
      })
      return routes.map(route => {
        const meta = route.meta || {}
        return {
          to: route,
          text: meta.label || this.$t(route.name)
        }
      })
    },
    props () {
      return {
        items: this.items,
        addClasses: this.addClasses,
        addLinkClasses: this.addLinkClasses,
        addLastItemClasses: this.addLastItemClasses
      }
    }
  }
}
</script>
