<template>
  <div class="c-app">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <PreLoading v-if="spinner" />
            <router-view :key="reloadRouter"></router-view>
          </CContainer>
        </main>
        <TheFooter />
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },

  computed: {
    //spinner for data response
    spinner() {
      return this.$store.state.isLoading;
    },
    //reload for language switch
    reloadRouter(){
      return this.$store.state.currentLanguage;
    }
  },
};
</script>

